import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import Post from '../components/blog/post'
import Seo from '../components/utils/seo'
import ContentBlocks from '../components/homepage/contentBlocks'

const Posts = () => {
    const postQuery = useStaticQuery(graphql`
        query {
            sanityBlog {
                ...BlogContentBlocksFragment
                seo {
                    description
                    title
                    image {
                        asset {
                            url
                        }
                    }
                }
                title
            }
            allSanityPost(sort: {fields: _createdAt, order: DESC}) {
                edges {
                node {
                    title
                    date(formatString: "Do MMMM YYYY")
                    image {
                        asset {
                            gatsbyImageData(aspectRatio:1)
                        }
                    }
                    id
                    slug {
                        current
                        }
                    }
                }
            }
        }
    `)
    const posts = postQuery.allSanityPost.edges
    const title = postQuery.sanityBlog.seo?.title
    const description = postQuery.sanityBlog.seo?.description
    const image = postQuery.sanityBlog.seo?.image?.asset.url
    return (
        <Layout>
            <Seo title={title} description={description} image={image} />
            <div className='container max-w-screen-xl mx-auto md:my-12 px-8'>
                <h1 className='text-5xl text-center mb-8'>{postQuery.sanityBlog.title}</h1>
                <ContentBlocks contentBlocks={postQuery.sanityBlog.contentBlocks} _rawContentBlocks={postQuery.sanityBlog._rawContentBlocks} />
                {posts.length > 0 ? (
                    <div className={`grid sm:grid-cols-2 gap-5 ${posts.length < 3 ? null : 'md:grid-cols-4'}`}>
                        {posts.map(post => (
                            <Post key={post.node.id} post={post.node} />
                        ))}
                    </div>
                ) : (
                    <h2>No posts..</h2>
                )}
            </div>
        </Layout>
    )
}

export default Posts
